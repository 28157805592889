import React from 'react';

const TC = ({ showTerms, setShowTerms }) => {
  return (
    showTerms && (
      <div className="modal">
        <div className="modal-content">
          <h3>Terms and Conditions</h3>
          <p>
            By using Chessroom, you agree to the following terms and conditions:
          </p>
          <ul>
            <li>
              <strong>Responsibility:</strong> Chessroom provides a platform for users to play chess while video chatting with strangers. The platform is not responsible for any offensive, harmful, or inappropriate behavior by users during the video chats or chess games. Users are solely responsible for their interactions.
            </li>
            <li>
              <strong>Bullying or Harassment:</strong> Chessroom is not responsible for any instances of bullying, harassment, or inappropriate behavior experienced on the platform. As a precaution, we recommend that users record their screen during interactions, similar to how dashcams are used in vehicles for protection against false accusations.
            </li>
            <li>
              <strong>User Conduct:</strong> By using the platform, you agree to behave respectfully and comply with applicable laws during your interactions.
            </li>
            <li>
              <strong>Disclaimer:</strong> Chessroom is not liable for any damages, losses, or issues arising from the use of the platform. Users accept that Chessroom is an open platform and that interactions are at their own risk.
            </li>
            <li>
              <strong>Data Collection:</strong> Chessroom does not collect any personal data or store any information about users. No login or signup is required to use the platform.
            </li>
          </ul>
          <button className="close-button" onClick={() => setShowTerms(false)}>
            Close
          </button>
        </div>
      </div>
    )
  );
};

export default TC;