import React, { useEffect, useState } from 'react';

const Header = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleHeaderClick = () => {
    window.location.href = '/';
  };

  useEffect(() => {
    // Delay the visibility so the fade-in can be seen
    setTimeout(() => setIsVisible(true), 100);
  }, []);

  const headerStyle = {
    cursor: 'pointer',
    opacity: isVisible ? 1 : 0,   // Controls the opacity
    transition: 'opacity 1.5s ease-in-out',  // Fade-in animation over 1.5 seconds
  };

  return (
    <header onClick={handleHeaderClick} style={headerStyle}>
      <h1>chessroom</h1>
    </header>
  );
};

export default Header;
