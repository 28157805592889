import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import SimplePeer from 'simple-peer';
import { Chess } from 'chess.js';
import { Chessboard } from 'react-chessboard';
import './Home.css';
import Footer from './Footer';
import TC from './TC';
import Header from './Header';
import './Game.css';
import './MobileHome.css';
import './MobileGame.css';
import { ToastContainer, toast } from 'react-toastify'; // Import react-toastify for alerts
import 'react-toastify/dist/ReactToastify.css'; // Import react-toastify CSS

// Update the WebSocket URL to your Render backend
const socket = io('https://chessica.onrender.com'); // Replace with your Render server address

const generateGameId = () => Math.random().toString(36).substr(2, 9);

const App = () => {
  const [onlineCount, setOnlineCount] = useState(0);
  const [inCall, setInCall] = useState(false);
  const [searching, setSearching] = useState(false);
  const [chess, setChess] = useState(new Chess());
  const [gameStarted, setGameStarted] = useState(false);
  const [color, setColor] = useState('white');
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [selectedSquare, setSelectedSquare] = useState(null); // For click-to-move
  const myVideo = useRef();
  const partnerVideo = useRef();
  const peerRef = useRef();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  

  // Set up portrait/landscape detection
  const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(orientation: portrait)");
    const handleOrientationChange = (event) => {
      setIsPortrait(event.matches);
    };

    mediaQuery.addEventListener('change', handleOrientationChange);

    return () => {
      mediaQuery.removeEventListener('change', handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    axios.get('https://chessica.onrender.com/count')
      .then(response => setOnlineCount(response.data.count))
      .catch(error => console.error(error));

    socket.on('updateCount', count => setOnlineCount(count));

    return () => {
      socket.off('updateCount');
    };
  }, []);

  const handlePlay = () => {
    if (isTermsChecked) {
      setSearching(true);
      socket.emit('play');
    } else {
      toast.error("Please accept the Terms and Conditions."); // Replacing alert with toast
    }
  };

  // Prevent fullscreen on iOS or other platforms when interacting with the video
  useEffect(() => {
    if (myVideo.current) { // Safeguard against undefined reference
      const videoElement = myVideo.current;
      const handleFullScreenChange = () => {
        if (document.fullscreenElement) {
          document.exitFullscreen(); // Automatically exit fullscreen if triggered
        }
      };

      videoElement.addEventListener('fullscreenchange', handleFullScreenChange);

      return () => {
        videoElement.removeEventListener('fullscreenchange', handleFullScreenChange);
      };
    }
  }, [myVideo.current]);

  useEffect(() => {
    socket.on('paired', async ({ initiator }) => {
      setInCall(true);
      setSearching(false);
      setGameStarted(true);
      setColor(initiator ? 'white' : 'black');

      const gameId = generateGameId();
      navigate(`/game`);

      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        myVideo.current.srcObject = stream;

        peerRef.current = new SimplePeer({
          initiator,
          trickle: true,
          stream,
          config: {
            iceServers: [
              { urls: 'stun:stun.l.google.com:19302' }, // Existing Google STUN server
              { urls: 'stun:bn-turn1.xirsys.com' }, // Xirsys STUN server
              {
                username: 'JvlmlYenMEVO4RkPFZocDBmQLBKRu4IURAwiP7_uRfAbaaeWRhLgn_LVCL0Qolz4AAAAAGcfX5I5Y2hha3U=',
                credential: 'c3f4a644-9512-11ef-b102-0242ac140004',
                urls: [
                  'turn:bn-turn1.xirsys.com:80?transport=udp',
                  'turn:bn-turn1.xirsys.com:3478?transport=udp',
                  'turn:bn-turn1.xirsys.com:80?transport=tcp',
                  'turn:bn-turn1.xirsys.com:3478?transport=tcp',
                  'turns:bn-turn1.xirsys.com:443?transport=tcp',
                  'turns:bn-turn1.xirsys.com:5349?transport=tcp'
                ]
              },
              {
                urls: [
                  "stun:bn-turn1.xirsys.com"
                ]
              },
              {
                urls: [
                  "turn:bn-turn1.xirsys.com:80?transport=udp",
                  "turn:bn-turn1.xirsys.com:3478?transport=udp",
                  "turn:bn-turn1.xirsys.com:80?transport=tcp",
                  "turn:bn-turn1.xirsys.com:3478?transport=tcp",
                  "turns:bn-turn1.xirsys.com:443?transport=tcp",
                  "turns:bn-turn1.xirsys.com:5349?transport=tcp"
                ],
                username: "zdnNXyCw37C79Z0Eu0yoVVMyBFRwVnbAd7Htf5jQ6IhR01att90TIsdkuoTF_cqFAAAAAGcjBRNqYWtlbG9uZw==",
                credential: "2dc52084-973f-11ef-916c-0242ac140004"
              },
              {
                urls: "stun:stun.relay.metered.ca:80",
              },
              {
                urls: "turn:global.relay.metered.ca:80",
                username: "45ef03b456888cb206581650",
                credential: "z6wbQYZVIfvU7np8",
              },
              {
                urls: "turn:global.relay.metered.ca:80?transport=tcp",
                username: "45ef03b456888cb206581650",
                credential: "z6wbQYZVIfvU7np8",
              },
              {
                urls: "turn:global.relay.metered.ca:443",
                username: "45ef03b456888cb206581650",
                credential: "z6wbQYZVIfvU7np8",
              },
              {
                urls: "turns:global.relay.metered.ca:443?transport=tcp",
                username: "45ef03b456888cb206581650",
                credential: "z6wbQYZVIfvU7np8",
              },
              {
                urls: "stun:stun.relay.metered.ca:80",
              },
              {
                urls: "turn:global.relay.metered.ca:80",
                username: "12230dfd0c990130f874a069",
                credential: "sDTsXyqZpO8VaIVH",
              },
              {
                urls: "turn:global.relay.metered.ca:80?transport=tcp",
                username: "12230dfd0c990130f874a069",
                credential: "sDTsXyqZpO8VaIVH",
              },
              {
                urls: "turn:global.relay.metered.ca:443",
                username: "12230dfd0c990130f874a069",
                credential: "sDTsXyqZpO8VaIVH",
              },
              {
                urls: "turns:global.relay.metered.ca:443?transport=tcp",
                username: "12230dfd0c990130f874a069",
                credential: "sDTsXyqZpO8VaIVH",
              },
              // Add the '9chaku' TURN server again at the end for retry
              {
                urls: [
                  "stun:bn-turn1.xirsys.com"
                ]
              },
              {
                username: 'JvlmlYenMEVO4RkPFZocDBmQLBKRu4IURAwiP7_uRfAbaaeWRhLgn_LVCL0Qolz4AAAAAGcfX5I5Y2hha3U=',
                credential: 'c3f4a644-9512-11ef-b102-0242ac140004',
                urls: [
                  'turn:bn-turn1.xirsys.com:80?transport=udp',
                  'turn:bn-turn1.xirsys.com:3478?transport=udp',
                  'turn:bn-turn1.xirsys.com:80?transport=tcp',
                  'turn:bn-turn1.xirsys.com:3478?transport=tcp',
                  'turns:bn-turn1.xirsys.com:443?transport=tcp',
                  'turns:bn-turn1.xirsys.com:5349?transport=tcp'
                ]
              }
            ],
          },
        });

        // Listen for ICE connection state change
        peerRef.current.on('connect', () => {
          console.log("Peer connected!");

          // Log ICE candidates to check if STUN or TURN is used
          peerRef.current._pc.getStats(null).then(stats => {
            stats.forEach(report => {
              if (report.type === 'candidate-pair' && report.state === 'succeeded') {
                console.log(`ICE connection state: ${report.state}`);
                console.log(`Local candidate type: ${report.localCandidateId}`);
                console.log(`Remote candidate type: ${report.remoteCandidateId}`);
              }
              if (report.type === 'local-candidate' || report.type === 'remote-candidate') {
                console.log(`Candidate ID: ${report.id}`);
                console.log(`Candidate Type: ${report.candidateType}`);  // This will show 'host', 'srflx', or 'relay'
                console.log(`Protocol: ${report.protocol}`);
                console.log(`Address: ${report.address}`);
                console.log(`Port: ${report.port}`);
              }
            });
          });
        });

        peerRef.current.on('signal', data => {
          socket.emit('signal', data);
        });

        peerRef.current.on('stream', stream => {
          partnerVideo.current.srcObject = stream;
        });

        socket.on('signal', data => {
          peerRef.current.signal(data);
        });
      } catch (error) {
        console.error("Error accessing media devices: ", error);
        toast.error("Failed to access camera and microphone. Please check your device permissions."); // Replacing alert with toast
      }
    });

    socket.on('move', (move, callback) => {
      const game = new Chess(chess.fen());
      game.move(move);
      setChess(game);
  
      // Acknowledge receipt of the move back to the server
      if (callback) {
        callback({ success: true });
      }

      // Check for game over conditions on move reception as well
      setTimeout(() => {
        if (game.isCheckmate()) {
          toast.success('Checkmate! The game is over.', { autoClose: false });
          socket.emit('gameOver', { type: 'checkmate' });
        } else if (game.isStalemate()) {
          toast.info('Stalemate! The game is a draw.'); // Notify both players of stalemate
          socket.emit('gameOver', { type: 'stalemate' });
        }
      }, 100); // Delay the notification to allow move rendering
    });

    // Handle game over events from the server
    socket.on('gameOver', ({ type }) => {
      if (type === 'checkmate') {
        toast.success('Checkmate! The game is over.');
      } else if (type === 'stalemate') {
        toast.info('Stalemate! The game is a draw.');
      }
    });

    // Listen for opponent leaving the game
    // Listen for opponent leaving the game
    socket.on('opponentLeft', () => {
      toast.info("Your opponent has left the game.", { autoClose: false });
      setInCall(false); // End the call
      
      // Redirect to home after a 3-second delay
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    });

    return () => {
      socket.off('paired');
      socket.off('signal');
      socket.off('move');
      socket.off('gameOver');
      socket.off('opponentLeft'); // Clean up opponentLeft event
    };
  }, [chess, navigate]);

  const handleMove = (sourceSquare, targetSquare) => {
    const game = new Chess(chess.fen());
    const move = { from: sourceSquare, to: targetSquare, promotion: 'q' };
  
    // Check if the player is already in check before the move
    const playerInCheckBeforeMove = game.inCheck();
  
    // Only allow the current player to move (white or black)
    if ((game.turn() === 'w' && color === 'white') || (game.turn() === 'b' && color === 'black')) {
      const validMoves = game.moves({ verbose: true });
      const isValidMove = validMoves.some(validMove => 
        validMove.from === sourceSquare && validMove.to === targetSquare
      );
  
      if (isValidMove) {
        game.move(move);
        setChess(game);
  
        // Function to retry sending the move to the server until acknowledged
        const sendMove = (move, retries = 10) => {
          if (retries <= 0) {
            toast.error("Failed to send move after multiple attempts.");
            return;
          }
  
          socket.emit('move', move, (response) => {
            if (!response || !response.success) {
              console.log('Move not acknowledged. Retrying...');
              setTimeout(() => sendMove(move, retries - 1), 5000); // Retry after 500ms
            } else {
              console.log('Move acknowledged by server.');
              console.log('retries: ', retries)
            }
          });
        };
  
        // Call sendMove to initiate the move with retry
        sendMove(move);
        setSelectedSquare(null);
  
        // Delay the check for checkmate and stalemate so the move is rendered first
        setTimeout(() => {
          if (game.isCheckmate()) {
            toast.success('Checkmate! The game is over.');
            socket.emit('gameOver', { type: 'checkmate' });
          } else if (game.isStalemate()) {
            toast.info('Stalemate! The game is a draw.');
            socket.emit('gameOver', { type: 'stalemate' });
          }
        }, 100);
      } else {
        if (playerInCheckBeforeMove) {
          toast.error("Invalid move! You're in check and must move out of check.");
        } else {
          console.error('Invalid move:', move);
        }
      }
    } else {
      console.warn('Not your turn:', move);
    }
  };

  const handleSquareClick = (square) => {
    const game = new Chess(chess.fen());
    const piece = game.get(square);

    if (selectedSquare) {
      // Check if the clicked square is valid for a move from the selected square
      const validMoves = game.moves({ square: selectedSquare, verbose: true });
      const isValidMove = validMoves.some(validMove => validMove.to === square);

      if (isValidMove) {
        handleMove(selectedSquare, square); // Call handleMove to process the move
      } else {
        // Invalid move and checking if the player is in check
        if (game.inCheck()) {
          toast.error("Invalid move! You're in check and must move out of check."); // Replacing alert with toast
        }
      }
      setSelectedSquare(null); // Clear the selection after attempting a move
    } else if (piece && ((piece.color === 'w' && color === 'white') || (piece.color === 'b' && color === 'black'))) {
      // Select the piece if no piece is selected and the clicked square has a piece of the player's color
      setSelectedSquare(square);
    } else {
      // Deselect if clicking on an empty or opponent's square
      setSelectedSquare(null);
    }
  };

  // Highlight the selected square with a border
  const customSquareStyles = {
    ...(selectedSquare && { 
      [selectedSquare]: { 
        boxShadow: 'inset 0 0 0 3px rgba(255, 255, 0, 0.6)' // Yellow inset box shadow around the inside of the selected square
      }
    })
  };

  return (
    <div className="app">
      <Header />
      <div className="content">
        <Routes>
          <Route
            path="/"
            element={
              !inCall && (
                <div className='main-content'>
                  <div className="title">Video Call and Play Chess with Strangers around the World</div>
                  
                  <p className="subtitle">Total People Online: {onlineCount}</p>
                  <div className="terms-and-conditions">
                    <input 
                      type="checkbox" 
                      id="terms" 
                      checked={isTermsChecked}
                      onChange={() => setIsTermsChecked(!isTermsChecked)}
                    />
                    <label htmlFor="terms" style={{ marginLeft: '8px' }}>
                      I agree to the{' '}
                      <span 
                        onClick={() => setShowTerms(true)} 
                        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                      >
                        Terms and Conditions
                      </span>
                    </label>
                  </div>
                  <br />
                  <button className="play-button" onClick={handlePlay} disabled={searching || !isTermsChecked}>
                    {searching ? 'Searching for an opponent...' : 'Play'}
                  </button>
                  <TC showTerms={showTerms} setShowTerms={setShowTerms} />
                </div>
              )
            }
          />

          <Route
            path="/game"
            element={
              inCall && (
                <div className="game-container">
                  <div className="chess-and-video">
                    {isPortrait && <div className="videos">
                      <video ref={myVideo} autoPlay muted className="video" playsInline controls={false} />
                      <video ref={partnerVideo} autoPlay className="video" playsInline controls={false} />
                    </div>}
                    <div className="chessboard-container">
                      <Chessboard
                        position={chess.fen()}
                        onPieceDrop={(sourceSquare, targetSquare) => handleMove(sourceSquare, targetSquare)}
                        onSquareClick={handleSquareClick} // Click-to-move handler
                        customSquareStyles={customSquareStyles} // Apply custom styles for square highlighting
                        customLightSquareStyle={{ backgroundColor: '#f5f5f5' }} // Custom light square color
                        customDarkSquareStyle={{ backgroundColor: '#717374' }} // Custom dark square color
                        boardOrientation={color}
                        boardWidth={isPortrait ? Math.min(window.innerWidth * 0.8, 600) : Math.min(window.innerWidth * 0.4, 600)} // 80% in portrait, 40% in landscape
                      />
                    </div>
                    {!isPortrait && <div className="videos">
                      <video ref={myVideo} autoPlay muted className="video" />
                      <video ref={partnerVideo} autoPlay className="video" />
                    </div>}
                  </div>
                </div>
              )
            }
          />
        </Routes>
      </div>

      {/* Conditionally render the Footer only on the '/' route */}
      {location.pathname === '/' && <Footer />}
      <ToastContainer /> {/* Add ToastContainer to enable toasts */}
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
